.tag {
  height: 36px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  color: #00bdd6ff; /* primary-500 */
  background: #ebfdffff;
}

.text-gallery {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #379ae6ff; /* info-500 */
  text-decoration: underline !important;
}
