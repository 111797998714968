.user-avatar {
    position: relative;

    .bx-edit-alt {
        left: 40px;
        font-size: 20px;
        position: absolute;
        bottom: 10px;
        cursor: pointer;
        display: none;
        transition: all 0.3s ease-in-out;
    }

    .bx-trash {
        right: 40px;
        font-size: 20px;
        position: absolute;
        bottom: 10px;
        cursor: pointer;
        display: none;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        .bx-edit-alt {
            display: block;
        }
        
        .bx-trash {
          display: block;
        }
    }
}
