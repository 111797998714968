.rmdp-input{
    padding: 9.3px 11px;
    font-size: 13px;
    line-height: 1.5;
    border-radius: 8px;
    width: 100%;
    min-height: 38px;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    transition: all 0.2s;
}

.rmdp-input:placeholder-shown {
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.25)
}

.rmdp-input::placeholder {
    color: rgba(0, 0, 0, 0.25)
}

.icon{
    color: rgba(0, 0, 0, 0.25);
}