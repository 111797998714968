.avatar{
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    // height: 7.5rem;
    // width: 7.5rem;
    .image-avatar{
        display: flex;
        position: relative;
    }
    .camera{
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #eff2f7;
        right: 55px;
        bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .action{
        display: none;
    }
    &:hover {
        .action{
            display: flex;
            position: absolute;
            top: 110px;
        }
    }
    .upload-avatar{
        display: none;
    }

}