//
// Google font - Open sans
//

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700&display=swap');


@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-Black.otf");
    font-weight: 700;
    font-style: normal;
  }
  
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-BlackOblique.otf");
    font-weight: 700;
    font-style: italic;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-Book.otf");
    font-weight: 300;
    font-style: normal;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-BookOblique.otf");
    font-weight: 300;
    font-style: italic;
  }

  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-Heavy.otf");
    font-weight: 500;
    font-style: normal;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-HeavyOblique.otf");
    font-weight: 500;
    font-style: italic;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-Light.otf");
    font-weight: 300;
    font-style: normal;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-LightOblique.otf");
    font-weight: 300;
    font-style: italic;
  }

  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-Medium.otf");
    font-weight: 500;
    font-style: normal;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-MediumOblique.otf");
    font-weight: 500;
    font-style: italic;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-Oblique.otf");
    font-weight: 400;
    font-style: italic;
  }
  
@font-face {
    font-family: "Avenir";
    src: url("../../../fonts/AvenirLTStd-Roman.otf");
    font-weight: 400;
    font-style: normal;
  }