/* Button 21 */
.button-primary {
  // width: 107px;
  height: 36px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffffff; /* white */
  background: #00bdd6ff; /* primary-500 */
  opacity: 1;
  border: none;
  gap: 6px;
  border-radius: 4px; /* border-m */
  &:hover {
    color: #ffffffff; /* white */
    background: #00a9c0ff; /* primary-550 */
  }
  /* Pressed */
  &:hover:active {
    color: #ffffffff; /* white */
    background: #0095a9ff; /* primary-600 */
  }
  /* Disabled */
  &:disabled {
    opacity: 0.4;
  }
}
/* Hover */

.button-import-export {
  @extend .button-primary;
  background-color: #ed7d2dff;
  &:hover {
    color: #ffffffff; /* white */
    background-color: #b1530fff;
  }
  /* Pressed */
  &:hover:active {
    color: #ffffffff; /* white */
    background-color: #b1530fff;
  }
  /* Disabled */
  &:disabled {
    opacity: 0.4;
  }
}
